import zodiac_border from "../../assets/images/lotto12v3/zodiac_border.png";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {padNumber2, sL12v2Label} from "../../constants/general";
import CountdownTimer from "../lotto12v2/CountdownTimer";
import {imgUrl} from "../../utils/asset";

const StatisticTable = ({list, drawing, t}) => {
    const {isDrawing, countDownTimer, drawCode, isWin, oldResult} = drawing;

    const Statistic = () => {
        return <div style={{
            backgroundImage: `url(${zodiac_border})`,
        }} className="content-left-bet-code">
            <div className="d-flex justify-content-center align-items-center">
                <p className="content-left-bet-issueno">{drawCode}</p>
                <CountdownTimer isDrawing={isDrawing} time={countDownTimer}/>
            </div>
            <div className="zodiac_border">
                <div className="movements">
                    {list.map((row, i) => {
                        return <div key={i} className="line">
                            {row.map((col, j) => {
                                return <div key={j} className="column">
                                <span
                                    className={`range1 s-${col}`}>  {col ? sL12v2Label[col] : ""}</span>
                                </div>
                            })}
                        </div>
                    })}

                </div>
            </div>
        </div>
    }

    const DrawingText = () => {
        return <div style={{
            backgroundImage: `url(${zodiac_border})`,
        }} className="content-left-bet-code justify-content-center">
            <div className="drawing-text">
                <div className="text-center">{drawCode}</div>
                {isWin && oldResult ? <div className="currentNumber_on">
                    <div className="text-result">{padNumber2(oldResult?.result)}</div>
                    <div className={`currentNumber_on_div s-${oldResult?.colorCode}`}/>
                    <img className="img-result" src={imgUrl(`lotto12v2/signs/${oldResult?.result}.png`)}
                         alt=""/>
                </div> : <p
                    className="text-center">{t("labels:drawing.lotto90.drawing")}</p>}
            </div>
        </div>
    }

    return isDrawing ? <DrawingText/> : <Statistic/>
}
const mapStateToProps = state => ({
    list: state.lotto12v2.statistic.list,
    drawing: state.lotto12v2.drawing
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(StatisticTable));

