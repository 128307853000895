const env = {
    BASE_URL: undefined,
    BASIC_TOKEN: undefined,
    PLATFORM_FOR: undefined,
    IS_ODD_EVEN: undefined,
    PLATFORM_COLOR_999: undefined,
    PLATFORM_TYPE: undefined,
    SOCKET_URL: undefined,
    SOCKET_IO_URL: undefined,
    FEATURES: undefined,
    PLATFORM_ALLOW: undefined,
    VERSION: undefined
};

env.BASE_URL = process.env.REACT_APP_BASE_URL;
env.BASIC_TOKEN = process.env.REACT_APP_BASIC_TOKEN;
env.PLATFORM_FOR = process.env.REACT_APP_STYLE_FOR;
env.IS_ODD_EVEN = process.env.REACT_APP_IS_ODD_EVEN;
env.PLATFORM_COLOR_999 = process.env.REACT_APP_STYLE_COLOR_999;
env.SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
env.SOCKET_IO_URL = process.env.REACT_APP_SOCKET_IO_URL;
env.PLATFORM_TYPE = process.env.REACT_APP_PLATFORM_FOR;
env.FEATURES = process.env.REACT_APP_FEATURES;
env.PLATFORM_ALLOW = process.env.REACT_APP_PLATFORM_ALLOW;
env.VERSION = process.env.REACT_APP_VERSION;

export {env};
