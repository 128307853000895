import axios from "axios";
import {
    LIST_DRAWING_LO12V2,
    LIST_STATISTIC_LO12V2, LOTTO12V2_FETCH_RECENT_BET_REPORT, LOTTO12V2_LIST_BETTING,
    LOTTO12V2_LIST_RECENT_DRAW, LOTTO12V2_REQUEST_FETCH_RESULT_REPORT,
    LOTTO12V2_REQUEST_FETCH_WIN_LOSS_REPORT,
} from "./types";
import API_LOTTO12 from "../../endpoints/lotto12v2";
import {imgUrl} from "../../utils/asset";
import {playAudio, requireAudio} from "../../constants/Audio";
import {getMute} from "../../utils/cached";
import {delay} from "../../utils/interval";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {PAGINATION} from "../rootConstant";
import moment from "moment";
import _ from "lodash";
import {getRecentSize} from "../user/action";
import {getRandomArbitrary} from "../../utils/util";


let roundInv;
let cardInv;
let winInv;
let borderInv;
let drawingSound = new Audio(requireAudio("lotto12v2/drawBgm.mp3"));

const playDrawingSound = () => {
    if (getMute() !== "ON") {
        drawingSound.currentTime = 0;
        drawingSound.play().then((_) => {
        }).catch((_) => {
        }).finally(() => {
        });
    }
}


const config = {
    round: 7,
    speed: 80,
    nextSpeed: 50,
    lastSpeed: 150,
    roundTimeOut: 2000,
    resultRound: 6,
    winTimeOut: 3000,
    winInv: 150,
    delayTime: 8
}

const configCircle = {
    initDeg: 7560,
    degPerSecond: 720,
    degPerNumber: 30,
    rotateTime: 13,
    startEaseOut: 2000,
    winInv: 500
}

const verifyDraw = (data, dispatch, isCircle) => {
    if (data !== null) {
        const timeCount = data.countDownTimer;
        data.isWin = false;
        clearAllInv();
        if (data.oldResult != null) {
            if (timeCount > 0) data.countDownTimer = 0;
            data.isDrawing = true;
            data.isStopBet = true;
            data.drawCode = data.oldResult.drawCode;
            isCircle
                ? startDrawingCircle(data, dispatch).then(r => r)
                : startDrawing(data, dispatch).then(r => r)
        } else {
            data.isStopBet = false;
            data.isDrawing = false;
            data.drawCode = data.newResult.drawCode;
        }
        dispatch({
            type: LIST_DRAWING_LO12V2,
            payload: data
        });
    }
}


const activeCard = (index, round) => {
    let sdIndex;
    let rdIndex;
    if (index === 1) {
        sdIndex = 12;
        rdIndex = 11;
    } else if (index === 2) {
        rdIndex = 12;
        sdIndex = index - 1;
    } else {
        sdIndex = index - 1;
        rdIndex = index - 2;
    }
    if (round >= config.resultRound)
        return [index]
    return [rdIndex, sdIndex, index]
}

const clearAllInv = () => {
    roundInv && clearInterval(roundInv);
    cardInv && clearInterval(cardInv);
    winInv && clearInterval(winInv);
    borderInv && clearInterval(borderInv);
    drawingSound && drawingSound.pause();
}

const getRound = (time) => {
    let round = parseInt(Math.abs(time) / 2);
    return round;
}

const borderAnimate = (name) => {
    let border = 1;
    borderInv && clearInterval(borderInv);
    borderInv = setInterval(() => {
        if (border === 1) border = 2
        else border = 1;
        let doc = document.getElementById("img-border-outline");
        if (doc) doc.src = imgUrl(`${name}${border}.png`);
    }, 200);
}


const startDrawing = async (data, dispatch) => {
    let {oldResult: {result}, countDownTimer} = data;
    let resultNumber = parseInt(result);
    let index = getRandomArbitrary(0, 12);
    let round = getRound(countDownTimer);
    countDownTimer = Math.abs(countDownTimer);
    let tempResult = [];

    borderAnimate("lotto12v2/bg-light-");
    playDrawingSound();

    const startRound = () => {
        round++;
        let speed = config.speed;
        if (round < config.resultRound) speed += config.nextSpeed;
        else speed += config.lastSpeed;

        cardInv && clearInterval(cardInv);
        cardInv = setInterval(() => {
            if (index >= 12) {
                index = 1
            } else index += 1;
            tempResult = activeCard(index, round);
            dispatch({
                type: LIST_DRAWING_LO12V2,
                payload: {
                    active: tempResult
                }
            });
            if (round >= config.resultRound && tempResult.includes(resultNumber)) {
                setWin(tempResult, dispatch);
            }

        }, speed);
    }

    await delay((config.delayTime - countDownTimer) * 1000);

    startRound();
    roundInv = setInterval(() => {
        if (round > config.round) {
            clearAllInv();
        } else {
            startRound();
        }
    }, config.roundTimeOut);

}


const startDrawingCircle = async (data, dispatch) => {
    let {oldResult: {result}, countDownTimer} = data;
    let resultNumber = parseInt(result);
    countDownTimer = Math.abs(countDownTimer);

    borderAnimate("lotto12v3/lucky_border");
    playDrawingSound();

    await delay((config.delayTime - countDownTimer) * 1000);
    if (countDownTimer >= 8) countDownTimer -= 8;
    let time = configCircle.rotateTime - countDownTimer;
    let arrow = document.getElementById("arrow-l12");
    let resImg = document.getElementById("lv3-result-img");
    if (!resImg) return;
    resImg.src = imgUrl(`lotto12v3/lucky-wheel-img-0.png`);
    let actualResultDeg = configCircle.degPerNumber * resultNumber;

    if (time <= 0) {
        arrow.style.transform = `rotate(${actualResultDeg}deg)`;
        arrow.style.transition = '';
        resImg.src = imgUrl(`lotto12v3/lucky-wheel-img-${resultNumber}.png`);
        return;
    }
    let resultDeg = configCircle.initDeg - (configCircle.degPerSecond * countDownTimer);
    let randomNumber = getRandomArbitrary(0, 12);

    let lastDeg = resultDeg + (randomNumber * configCircle.degPerNumber);
    arrow.style.transform = `rotate(${lastDeg}deg)`;
    arrow.style.transition = `transform ${time}s ease-in 0s`;
    resultDeg += actualResultDeg;

    let isActive = false;
    setTimeout(() => {
        arrow.style.transform = `rotate(${resultDeg}deg)`;
        arrow.style.transition = `transform ${time}s ease-out 0s`;
        arrow.addEventListener(
            'webkitTransitionEnd',
            function (event) {
                arrow.style.transition = "";
                dispatch({
                    type: LIST_DRAWING_LO12V2,
                    payload: {
                        isWin: true
                    }
                });
                playAudio("lotto12v2/result.mp3");
                winInv && clearInterval(winInv);
                winInv = setInterval(() => {
                    isActive = !isActive;
                    if (isActive) resImg.src = imgUrl(`lotto12v3/lucky-wheel-img-${resultNumber}.png`);
                    else resImg.src = imgUrl(`lotto12v3/lucky-wheel-img-0.png`);
                }, configCircle.winInv);
            }, false);
    }, configCircle.startEaseOut);

}

const setWin = (tempResult, dispatch) => {
    roundInv && clearInterval(roundInv);
    cardInv && clearInterval(cardInv);
    let isActive = false;
    dispatch({
        type: LIST_DRAWING_LO12V2,
        payload: {
            isWin: true
        }
    });
    playAudio("lotto12v2/result.mp3");
    winInv && clearInterval(winInv);
    winInv = setInterval(() => {
        isActive = !isActive;
        dispatch({
            type: LIST_DRAWING_LO12V2,
            payload: {
                active: isActive ? tempResult : []
            }
        });
    }, config.winInv);
}


const fetchLotto12v2DrawingResult = (isCircle = false) => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LOTTO12.FETCH_LOTTO12V2_DRAWING_RESULT);
        let data = response.data.data;
        // let data = drawing12;
        verifyDraw(data, dispatch, isCircle);
        return response;
    } catch (error) {
        return await Promise.reject(error);
    }
};


const fetchStatistic12v2 = (payload) => async (dispatch) => {
    const filter = {row: 6, column: 12, skip: 1, ...payload};
    try {
        const response = await axios.get(API_LOTTO12.FETCH_LOTTO12V2_STATISTIC, {
            params: filter
        });
        const data = response.data.data;
        if (data && data.numberItems) {
            let list = data.numberItems.map(e => {
                return e.map(p => {
                    return p.split("_")[1] ?? "";
                })
            })
            dispatch({
                type: LIST_STATISTIC_LO12V2,
                payload: list
            });
        }

    } catch (error) {
        return await Promise.reject(error);
    }
};

const fetchLotto12v2RecentDraw = () => (dispatch) => {
    return axios
        .get(API_LOTTO12.LOTTO12V2_FETCH_RECENT_DRAW)
        .then((response) => {
            let data = response.data.data;
            dispatch({
                type: LOTTO12V2_LIST_RECENT_DRAW,
                payload: data
            });
            dispatch({
                type: LIST_DRAWING_LO12V2,
                payload: {
                    active: [parseInt(data[0].result)]
                }
            });
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const fetchLotto12v2WinLossReport = (payload) => (dispatch) => {
    dispatch(showLoading("sectionBar"));

    return axios.get(API_LOTTO12.FETCH_LOTTO12V2_WIN_LOSS_REPORT, {
        params: {filterByLotto: "YUKI", ...payload}
    })
        .then((response) => {
            const paging = response.data.paging;
            const data = response.data.data;

            dispatch({
                type: LOTTO12V2_REQUEST_FETCH_WIN_LOSS_REPORT,
                payload: {...data, ...paging},
            });

            dispatch(hideLoading("sectionBar"));

        })
        .catch((error) => {
            dispatch(hideLoading("sectionBar"));
            return Promise.reject(error);
        });
};

const fetchLotto12v2ResultReport = (payload) => async (dispatch) => {

    try {
        const response = await axios
            .get(API_LOTTO12.FETCH_LOTTO12V2_RESULT_REPORT, {params: {size: PAGINATION.SIZE, page: 1, ...payload}});
        let items = [];
        const paging = response.data.paging;

        if (response.data.data.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
                let data = response.data.data[i];
                let row = {
                    ...data,
                    code: data.drawCode,
                    title: data.drawLabel,
                    createdAt: moment(data.createdAt).format("YYYY-MM-DD"),
                    createdAtTime: moment(data.createdAt).format("HH:mm"),
                };
                items[i] = row;
            }
        }

        dispatch({
            type: LOTTO12V2_REQUEST_FETCH_RESULT_REPORT,
            payload: {
                items: items,
                filter: payload,
                ...paging
            },
        });

    } catch (error) {
        return await Promise.reject(error);
    }

};


const fetchLotto12v2ListBetting = (payload) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto12v2") || "[]");
    let item = {
        betList: betting,
        summary: {
            total: 0,
            currency: "",
            currencySignal: "",
        },
    };
    if (betting?.length > 0)
        item.summary.total = _.sumBy(betting, 'amount');

    if (payload.isClear) {
        return dispatch({
            type: LOTTO12V2_LIST_BETTING,
            payload: item,
        });
    }
    return item.summary.total;
};

const addLotto12v2Betting = (payload) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto12v2"));
    if (_.isEmpty(betting)) betting = [];
    betting.push(payload);
    sessionStorage.setItem("betting_lotto12v2", JSON.stringify(betting))
    dispatch(fetchLotto12v2ListBetting({isClear: true}));
};

const clearCartLotto12v2 = () => (dispatch) => {
    sessionStorage.removeItem("betting_lotto12v2");
    dispatch(fetchLotto12v2ListBetting({isClear: true}));
};

const deleteLotto12v2Betting = (payload) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto12v2"));
    betting.splice(payload.id, 1);
    sessionStorage.setItem("betting_lotto12v2", JSON.stringify(betting))
    dispatch(fetchLotto12v2ListBetting({isClear: true}));
};


const lotto12v2ConfirmBettingNow = (callback) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto12v2"));
    const payload = {
        "drawCode": "",
        "gameType": "YUKI",
        "items": []
    };
    if (betting?.length > 0) {
        payload.drawCode = betting[betting.length - 1].drawCode;
        payload.items = betting;
    }
    callback();
    return axios
        .post(API_LOTTO12.LOTTO12_BETTING_NOW, payload)
        .then(async (response) => {
            await sessionStorage.removeItem("betting_lotto12v2");
            await dispatch(fetchLotto12v2ListBetting({isClear: true}));
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });

};


const fetchLotto12v2RecentBetReport = () => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LOTTO12.FETCH_LOTTO12V2_RECENT_BET_REPORT, {
                params: {
                    size: getRecentSize().yuki,
                    page: 1,
                    filterByLotto: "YUKI",
                    groupByDraw: "YES",
                }
            });

        dispatch({
            type: LOTTO12V2_FETCH_RECENT_BET_REPORT,
            payload: response.data.data,
        });

        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

export {
    fetchLotto12v2DrawingResult,
    fetchStatistic12v2,
    fetchLotto12v2RecentDraw,
    clearAllInv,
    fetchLotto12v2WinLossReport,
    fetchLotto12v2ResultReport,
    fetchLotto12v2ListBetting,
    addLotto12v2Betting,
    lotto12v2ConfirmBettingNow,
    fetchLotto12v2RecentBetReport,
    clearCartLotto12v2,
    deleteLotto12v2Betting
}


